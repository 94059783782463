<template>
    <div class="border dark:border-0 bg-white dark:bg-gray-800 fix-rounding-safari w-full h-full relative flex flex-col overflow-scroll">

        <Modal v-if="selectedPhoto" @hide="selectedPhoto = null" maxWidth="max-w-4xl !p-0">
            <img :src="selectedPhoto" class="w-full" />
        </Modal>

        <div
            class="top-4 md:top-8 px-4 md:px-8 inset-x-0 flex flex-col-reverse md:flex-row z-50 flex justify-between items-start md:space-x-8 pointer-events-none"
            :class="selectedView == 'opportunities' ? 'absolute' : 'py-8'"
        >

            <!-- p-4 bg-white rounded-xl shadow-card -->
            <div class="flex flex-col items-start space-y-2 pointer-events-auto">

                <div v-if="admin" class="flex bg-white shadow-xl rounded-xl divide-x overflow-hidden border">
                    <label
                        v-for="t in entityTypeOptions" :key="t"
                        :class="{'text-blue-500 bg-blue-50': entityTypes.includes(t)}"
                        class="px-3 py-2 flex items-center space-x-1"
                    >
                        <input type="checkbox" class="" v-model="entityTypes" :value="t" />
                        <span class="text-2xs md:text-xs">{{ t.toTitleCase() }}</span>
                    </label>
                </div>

                <MultiSelect
                    v-if="admin || branches?.length > 1"
                    collection="branches"
                    class="w-full rounded-xl flex bg-white shadow-xl bg-white text-xs md:text-sm"
                    v-model:modelSelectedIds="branchIds"
                    v-model:modelSelectedEntries="branchEntries"
                    buttonText="Filter Branches"
                    placeholder="Search Branches"
                    :inputStyle="`w-64 text-xs md:text-sm !rounded-xl !border ${branchIds?.length ? `-mt-2` : ''}`"
                    :algoliaFilters="branchSearchFilters"
                    :multiple="true"
                    :create="false"
                    :movable="false"
                />

                <div class="drop-shadow-xl w-full flex flex-col items-start">
                    <MultiSelect
                        collection="members"
                        class="w-full rounded-xl flex bg-white bg-white text-xs md:text-sm"
                        v-model:modelSelectedIds="repIds"
                        v-model:modelSelectedEntries="repEntries"
                        buttonText="Filter Reps"
                        placeholder="Search Reps"
                        :inputStyle="`w-64 text-xs md:text-sm !rounded-xl !border ${repIds?.length ? `-mt-2` : ''}`"
                        :algoliaFilters="repSearchFilters"
                        :multiple="true"
                        :create="false"
                        :movable="false"
                    />

                    <div v-if="route" class="flex bg-white shadow-xl rounded-b-xl overflow-hidden border text-xs md:text-sm p-2 space-x-2 font-semibold !-mt-2 pt-4">
                        <div class="text-green-600">{{ convertDuration(route.duration) }}</div>
                        <div class="">{{ (route.distance / 1609.34).toFixed(2) }} mi</div>
                    </div>
                </div>

                <div class="flex bg-white shadow-xl rounded-xl divide-x overflow-hidden border text-xs md:text-base">
                    <button
                        v-for="v in viewOptions" :key="v"
                        class="px-3 py-2" :class="{'text-blue-500 bg-blue-50': v == selectedView}"
                        @click="selectedView = v"
                    >
                        <i class="fal" :class="`fa-${viewOptionIcons[v]}`" />
                    </button>
                </div>

            </div>

            <div v-if="leadMarkers.length" class="flex flex-col items-center mb-2 md:mb-0 pointer-events-auto">
                <!-- <div class="px-8 py-2 bg-white rounded-t-xl font-mono uppercase tracking-widest font-bold shadow-card">Today's Live Totals</div> -->
                <div class="flex flex-col justify-center items-center p-2 md:p-4 bg-white shadow-card rounded-xl w-[calc(100vw-2rem)] md:w-full overflow-x-scroll border md:border-0">
                    <div class="flex space-x-2 md:space-x-4 lg:space-x-2 2xl:space-x-4 font-mono mx-auto">
                        <div v-for="total, key in totals" :key="key" :class="theme[key]" class="p-2 rounded-xl flex flex-col items-center justify-center relative truncate md:shadow-card">
                            <div class="text-2xs md:text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key.toTitleCase('camel') }}</div>
                            <div class="text-sm md:text-lg md:text-xl 3xl:text-2xl">{{ isNaN(total) ? total : total.toLocaleString() || 0 }}</div>
                        </div>
                    </div>
                </div>
                <div class="hidden md:block px-8 py-2 bg-white rounded-b-xl font-mono uppercase tracking-widest font-bold shadow-card">
                    {{ partner ? partner.firstName : 'Today' }}{{ partner?.firstName?.split('')?.pop() == 's' ? `'` : `'s`}}
                    Live Totals
                </div>
            </div>

            <div class="hidden 3xl:flex w-full max-w-xs">
                <div v-if="partner" class="bg-white shadow-xl rounded-xl divide-x p-4 w-full">
                    <div class="flex items-center space-x-4">
                        <People :people="[partner]" class="shrink-0" />
                        <div class="w-full flex justify-between items-center">
                            <div>
                                <div class="opacity-40 text-xs uppercase">Partner</div>
                                <h1 class="text-lg md:text-xl">{{ partner.title }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="partner" class="hidden 3xl:flex bg-white shadow-xl rounded-xl divide-x p-4">
                <div class="flex items-center space-x-4">
                    <People :people="[store.user]" class="shrink-0" />
                    <div class="w-full flex justify-between items-center">
                        <div>
                            <div class="opacity-40 text-xs">Joined: {{ dayjs(store.user?.created).format('MMM D, YYYY') }}</div>
                            <h1 class="text-lg md:text-xl">{{ store.user.title }}</h1>
                            <div class="opacity-50">{{ store.user.branches?.[0]?.title }}</div>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>

        <div v-if="!leadMarkers.length" class="w-full h-full flex items-center justify-center">
            <div class="w-full h-full flex flex-col justify-center items-center p-8 text-center">
                <div class="text-2xl font-bold opacity-20">No Opportunities Today</div>
                <div class="opacity-40 text-sm mt-2">Once sales reps gather some opportunities they will appear here.</div>
            </div>
        </div>

        <!-- Limit the hight of this box to the size remaining in its' parent -->
        <div v-else class="w-full flex-grow overflow-scroll">
            <div v-show="selectedView == 'opportunities'" class="w-full h-full">
                <Mapbox
                    :startDate="startRange"
                    :markers="leadMarkers"
                    :hover="true"
                    v-model:hoveredMarker="hoveredOpportunityId"
                    ref="map"
                    @markersLoaded="$refs.map?.fitBounds"
                    @update:route="route = $event"
                />
            </div>

            <div v-show="selectedView == 'reps'" class="w-full ">
                <Mango collection="members" :infinite="true" :query="repQuery" @update:data="reps = $event" class="md:pt-16">
                    <div v-if="repStats?.length" class="flex flex-col items-start divide-y">
                        <div class="flex font-mono mx-auto flex sticky top-0 md:top-0 z-20 shadow-card border-b-2">
                            <div class="flex items-center shrink-0 w-32 md:w-[17rem] md:max-w-[17rem] pr-4 md:pr-0 bg-white bg-slate-100 left-0 sticky z-10 pl-4 border-r-2"><span class=" md:block mr-1">Sales</span>Rep</div>
                            <div v-for="total, key in repStats[0].totals" :key="key" :class="theme[key]" class="w-32 shrink-0 py-2 px-4 flex flex-col items-center justify-center relative truncate shadow-card">
                                <!-- <div class="md:hidden text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key[0] }}</div> -->
                                <div class="text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key.toTitleCase('camel').replace('Opportunities', 'Ops') }}</div>
                            </div>
                        </div>
                        <div v-for="rep in repStats" :key="rep.id" class="hover:bg-gray-50 cursor-pointer mx-auto">
                            <div class="flex">
                                <div class="flex flex-col md:flex-row w-32 md:space-x-4 shrink-0 md:w-[17rem] md:max-w-[17rem] pr-4 md:pr-0 left-0 pl-4 sticky z-10 bg-white border-r-2">
                                    <People :people="[rep]" class="hidden md:block shrink-0 self-center pt-2 md:pt-0" />
                                    <button @click="setRep(rep)" class="truncate text-xs md:hidden py-2 text-left">{{ rep.title }}</button>
                                    <div class="hidden md:flex justify-between items-center">
                                        <div class="py-4">
                                            <div class="opacity-40 text-2xs">Joined: {{ dayjs(rep?.created).format('MMM D, YYYY') }}</div>
                                            <button class="hover:underline text-left" @click="setRep(rep)">{{ rep.title }}</button>
                                            <div v-if="branchIds?.length != 1 && branches?.length != 1" class="opacity-50 text-xs">{{ rep.branches?.[0]?.title }}</div>
                                            <div v-if="rep?.emoji" class="flex items-center space-x-2">
                                                <div v-for="v, e in rep.emoji.reduce((a,e) => {a[e] += 1; return a}, {'🦁':0,'🐋':0,'🦣':0})" :key="e" class="text-xs">
                                                    <div v-if="v">{{ e }} {{v}}x</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex font-mono mx-auto divide-x">
                                    <div v-for="total, key in rep.totals" :key="key" :class="theme[key]" class="w-32 shrink-0 bg-opacity-20 px-2 flex flex-col items-center justify-center">
                                        <div class="text-lg">{{ isNaN(total) ? total : total.toLocaleString() || 0 }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Mango>
            </div>

            <div v-show="selectedView == 'branches'" class="w-full ">
                <div class="md:pt-16" v-if="branchStats?.length">
                    <div class="flex flex-col items-start divide-y">
                        <div class="flex font-mono mx-auto flex sticky top-0 md:top-0 z-20 shadow-card border-b-2">
                            <div class="flex items-center shrink-0 w-32 md:w-[17rem] md:max-w-[17rem] pr-4 md:pr-0 bg-white bg-slate-100 left-0 sticky z-10 pl-4 border-r-2">Branch</div>
                            <div v-for="total, key in branchStats[0].totals" :key="key" :class="theme[key]" class="w-32 shrink-0 py-2 px-4 flex flex-col items-center justify-center relative truncate shadow-card">
                                <!-- <div class="md:hidden text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key[0] }}</div> -->
                                <div class="text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key.toTitleCase('camel').replace('Opportunities', 'Ops') }}</div>
                            </div>
                        </div>
                        <div v-for="branch in branchStats" :key="branch.id" class="hover:bg-gray-50 cursor-pointer mx-auto">
                            <div class="flex">
                                <div class="flex flex-col md:flex-row w-32 md:space-x-4 shrink-0 md:w-[17rem] md:max-w-[17rem] pr-4 md:pr-0 left-0 pl-4 sticky z-10 bg-white border-r-2">
                                    <People :people="[branch]" class="hidden md:block shrink-0 self-center pt-2 md:pt-0" />
                                    <button @click="setBranch(branch)" class="truncate text-xs md:hidden py-2 text-left">{{ branch.title }}</button>
                                    <div class="hidden md:flex justify-between items-center">
                                        <div class="py-4">
                                            <div class="opacity-40 text-2xs">Joined: {{ dayjs(branch?.created).format('MMM D, YYYY') }}</div>
                                            <button class="hover:underline text-left" @click="setBranch(branch)">{{ branch.title }}</button>
                                            <!-- <div v-if="branchIds?.length != 1 && branches?.length != 1" class="opacity-50 text-xs">{{ rep.branches?.[0]?.title }}</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="flex font-mono mx-auto divide-x">
                                    <div v-for="total, key in branch.totals" :key="key" :class="theme[key]" class="w-32 shrink-0 bg-opacity-20 px-2 flex flex-col items-center justify-center">
                                        <div class="text-lg">{{ isNaN(total) ? total : total.toLocaleString() || 0 }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="selectedView == 'partners'" class="w-full px-8 pb-16">
                <div class="text-center text-3xl mt-8">Select a Partner</div>
                <Mango
                    collection="members" :query="{search: {roles: 'seniorPartner'}}" :infinite="true" v-slot="{data: partners, loading}"
                    class="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 lg:gap-16 gap-6 mt-16 max-w-7xl mx-auto"
                >
                    <Spinner v-if="loading" />
                    <button
                        v-for="partner in partners"
                        :key="partner.id"
                        :to="`/${path}/${partner?.id}`"
                        @click="setPartner(partner)"
                        class="flex items-center justify-start hover:-rotate-1 hover:scale-105 transition-all group space-x-6 w-full text-left"
                    >
                        <div
                            :style="`background-image: url('${partner.image?.url}')`"
                            class="bg-cover bg-center w-20 h-20 shrink-0 rounded-full flex items-center justify-center bg-gray-100 border-2"
                        ><i v-if="!partner.image?.url" class="fad text-2xl fa-building"/>
                        </div>
                        <div class="flex flex-col">
                            <!-- <div class="opacity-30 text-2xs uppercase tracking-widest font-bold">Since {{ dayjs(partner.created).format('MMM \'YY') }}</div> -->
                            <div class="opacity-30 text-2xs uppercase tracking-widest font-bold">{{ partner?.branches?.length || 0 }} Branches</div>
                            <div class="text-lg font-semibold z-10 group-hover:underline">{{ partner.title }}</div>
                        </div>
                    </button>
                </Mango>
            </div>

        </div>

    </div>

</template>

<script>
import Mapbox from '../components/network/mapbox.vue'
import Modal from '../components/layout/modal.vue'
import MultiSelect from '../helpers/multiSelect.vue'
import Mango from '../helpers/mango'
import People from '../components/layout/people.vue'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export default {
    props: ['opportunities', 'startRange'],
    inject: ['darkMode','store'],
    components: {Mapbox, Modal, MultiSelect, People},
    data() {
        return {
            loading: false,
            partner: null,
            selectedPhoto: null,
            selectedView: this.$route.params?.tab || 'opportunities',
            hoveredOpportunityId: null,
            loadingBranches: false,
            branchModalOpen: false,
            oneMonthAgo: new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime(),
            oneWeekAgo: new Date(new Date().setDate(new Date().getDate() - 7)).getTime(),
            view: 'week',
            branchIds: [],
            branchEntries: [],
            branchesData: [],
            reps: [],
            repIds: [],
            repEntries: [],
            route: null,
            entityType: null,
            entityTypes: ['corporate','franchise','partner'],
            entityTypeOptions: ['corporate','franchise'],
            branchMap: null,
            theme: {
                knock: 'bg-red-200',
                talk: 'bg-red-200',
                walk: 'bg-red-200',
                contingency: 'bg-red-200',
                approvals: 'bg-orange-200',
                contracts: 'bg-yellow-200',
                revenue: 'bg-green-200',
                softSets: 'bg-blue-200',
                salesOpportunities: 'bg-purple-200',
                estimate: 'hidden',
            },
            viewOptionIcons: {
                reps: 'users',
                opportunities: 'house',
                branches: 'building',
                partners: 'handshake',
            },
        }
    },
    // async created() {
    //     let admin = this.store?.user?.roles?.includes('admin')
    //     let myBranchIds = this.store?.user?.branches.map(b => b.id)
    //     let search = {}
    //     if (!admin) search.id = {$in: myBranchIds}
    //     this.branchesData = await Mango.branches({search, fields: ['title','image','created'], limit: 1000})
    // },
    methods: {
        dayjs,
        convertDuration(duration) {
            return dayjs.duration(duration, 'seconds').format('H[h] mm[m]')
        },
        inRange(l, param) {
            let endRange = new Date()
            param = l?.timestamps?.[param] || l?.[param]
            if (param) param = new Date(param)
            let inRange = param < endRange && param > this.startRange
            return inRange
        },
        calculateTotals(ops) {
            // Calculate the revenue
            let revenueOpportunities = ops.filter(l => this.inRange(l, 'revenue'))
            let revenueAlreadyReported = revenueOpportunities.reduce((a, l) => a + (isNaN(l.revenueReported) ? 0 : Number(l.revenueReported)), 0)
            let totalRevenue = revenueOpportunities.reduce((a, l) => a + (isNaN(l.revenue) ? 0 : Number(l.revenue)), 0)
            let netRevenue = totalRevenue - revenueAlreadyReported

            // console.log('netRevenue', netRevenue, revenueAlreadyReported)

            return {
                knock: ops.filter(l => this.inRange(l, 'knock')).filter(l => !!l.ktwc?.knock).length,
                talk: ops.filter(l => this.inRange(l, 'talk')).filter(l => !!l.ktwc?.talk).length,
                walk: ops.filter(l => this.inRange(l, 'walk')).filter(l => !!l.ktwc?.walk).length,
                contingency: ops.filter(l => this.inRange(l, 'contingency')).filter(l => !!l.ktwc?.contingency).length,

                approvals: ops.filter(l => this.inRange(l, 'insuranceAccepted')).filter(l => !!l.insuranceAccepted).length,
                contracts: ops.filter(l => this.inRange(l, 'contractSigned')).filter(l => !!l.contractSigned).length,
                revenue: netRevenue.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }),
                softSets: ops.filter(l => this.inRange(l, 'softSet')).filter(l => l?.inspection?.toLowerCase?.() === 'soft set' || l?.discussion?.toLowerCase?.() === 'soft set').length,
                salesOpportunities: ops.filter(l => this.inRange(l, 'created')).length,

                estimate: ops.filter(l => this.inRange(l, 'estimate')).reduce((a, l) => a + (l.estimate || 0), 0),
            }
        },
        setRep(rep) {
            this.repIds = [rep.id]
            this.repEntries = [rep]
            this.selectedView = 'opportunities'
        },
        setBranch(branch) {
            this.branchIds = [branch.id]
            this.branchEntries = [branch]
            this.selectedView = 'opportunities'
        },
        async setPartner(partner) {
            // let branches = await Mango.branches({ search: {id: {$in: partner.branches}}, fields: ['title','image','created'], limit: 1000 })
            this.branchIds = partner.branches.map(b => b.id)
            this.branchEntries = JSON.parse(JSON.stringify(partner.branches))
            this.selectedView = 'opportunities'
            this.entityTypes = [...this.entityTypeOptions]
            this.$nextTick(() => this.partner = partner)
        },
    },
    async mounted() {
        this.loadingBranches = true
        this.branchMap = await Mango.branches({ fields: ['entityType','title','image','created'], limit: 999, search: {status: 'open'} })
        this.loadingBranches = false
    },
    watch: {
        async entityType() {
            if (this.entityType) {
                this.branchEntries = []
                this.branchIds = []
            }
        },
        leadMarkers() {
            if (this.repIds?.length === 1) {
                setTimeout(() => {
                    // this.$refs.map?.drawRouteLine()
                    this.$refs.map?.getOptimalRoute()
                }, 1500)
            } else {
                this.$refs.map?.removeRouteLine()
            }
        },
        repEntries: {
            handler() {
                this.partner = null
            },
            deep: true,
        },
        branchEntries: {
            handler() {
                this.partner = null
            },
            deep: true,
        },
        entityTypes() {
            console.log('entityTypes')
            this.partner = null
        },
        // selectedView() {
        //     this.$router.push(`/dashboard/${this.selectedView}`)
        // },
    },
    computed: {
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        branches() {
            if (!this.admin) {
                return this.store?.user?.branches || []
            }
        },
        viewOptions() {
            let viewOptions = ['reps', 'opportunities']
            if (this.branches?.length > 1 || this.admin) viewOptions.push('branches')
            if (this.admin) viewOptions.push('partners')
            return viewOptions
        },
        branchSearchFilters() {
            if (this.branches) {
                return this.branches.map(branch => `objectID:${branch.id}`).join(' OR ');
            }
        },
        repSearchFilters() {
            let branches = this.branches || this.branchEntries
            if (branches) {
                return branches.map(branch => `branches.id:${branch.id}`).join(' OR ');
            }
        },
        repStats() {
            return this.reps?.map(r => {
                let ops = this.filteredOpportunities.filter(o => [o.authorId, ...(o.shotgun?.map(s => s.id)||[])].some(id => id == r.id))
                let totals = this.calculateTotals(ops)
                return {
                    ...r,
                    totals
                }
            })
            .sort((a, b) => Number(b.totals.revenue.replace(/[\$,]/g, '').replace('.', '')) < Number(a.totals.revenue.replace(/[\$,]/g, '').replace('.', '')) ? 1 : -1)
            .sort((a, b) => b.totals?.knock > a.totals?.knock ? 1 : -1)
        },
        branchStats() {
            let branches = this.branchEntries?.length ? this.branchEntries : this.admin ? this.branchMap : this.branches
            return branches?.map(r => {
                let ops = this.filteredOpportunities.filter(o => o.branch.id == r.id)
                let totals = this.calculateTotals(ops)
                return {
                    ...r,
                    totals
                }
            })
            .sort((a, b) => Number(b.totals.revenue.replace(/[\$,]/g, '').replace('.', '')) < Number(a.totals.revenue.replace(/[\$,]/g, '').replace('.', '')) ? 1 : -1)
            .sort((a, b) => b.totals?.knock > a.totals?.knock ? 1 : -1)
        },
        repQuery() {

            if (this.branchIds?.length && this.branchIds?.length <= 2) {
                return { search: { 'branches': { $in: this.branchIds }, status: {$nin: ['closed','disabled']}, level: {$lte: 3} }, limit: 200, fields: ['title', 'branches', 'emoji', 'image'] }
            }

            // Create an object to track revenue and opportunities per user
            let userNumbers = {};

            // Iterate over each opportunity
            this.filteredOpportunities.forEach(opportunity => {
                // Add revenue for the opportunity's author
                if (opportunity.authorId) {
                    // if (typeof opportunity.authorId != 'string') console.log('opportunity', opportunity)
                    if (!userNumbers[opportunity.authorId]) {
                        userNumbers[opportunity.authorId] = { opportunities: 0, revenue: 0 };
                    }
                    userNumbers[opportunity.authorId].opportunities += 1;
                    userNumbers[opportunity.authorId].revenue += opportunity.revenue || 0;
                }

                // Add revenue for each user in the shotgun field
                if (opportunity.shotgun && Array.isArray(opportunity.shotgun)) {
                    opportunity.shotgun.forEach(user => {
                        if (!userNumbers[user.id]) {
                            userNumbers[user.id] = { opportunities: 0, revenue: 0 };
                        }
                        // Note: Only add revenue, not opportunities, for shotgun users
                        userNumbers[user.id].revenue += opportunity.revenue || 0;
                    });
                }
            });

            // Reduce to top 50 based on total revenue
            let sortedUserNumbers = Object.keys(userNumbers)
                .map(userId => ({ userId, ...userNumbers[userId] }))
                .sort((a, b) => b.revenue - a.revenue)
                .slice(0, 50);

            let userIds = sortedUserNumbers.map(user => user.userId);
            return { search: { compareId: { in: userIds } }, limit: 50, fields: ['title', 'branches', 'emoji', 'image'] }

        },
        entities() {
            return this.branchMap?.reduce((acc, b) => {
                if (!acc[b.entityType]) acc[b.entityType] = [b.id]
                else acc[b.entityType].push(b.id)
                return acc
            }, {})
        },
        filteredOpportunities() {
            let filtered = this.opportunities

            let includedBranches = this.entityTypes.reduce((acc, type) => {
                return acc.concat(this.entities?.[type])
            }, [])
            filtered = filtered?.filter(o => includedBranches.includes(o.branch?.id))

            if (this.branchIds.length) filtered = filtered.filter(o => this.branchIds.includes(o.branch?.id))
            if (this.repIds.length) filtered = filtered.filter(o => [o.authorId, ...(o.shotgun?.map(s => s.id)||[])].some(id => this.repIds.includes(id)))

            return filtered
        },
        totals() {
            return this.calculateTotals(this.filteredOpportunities)
        },
        revenue() {
            let revenue = this.filteredOpportunities?.reduce((acc, opportunity) => {
                return acc + (opportunity.revenue || 0)
            }, 0)

            // Add a dollar sign to the beginning
            return `$${revenue.toLocaleString()}`;
        },
        leadMarkers() {
            let colorStatusMap = {
                closed: 'border border-red-400/50 text-red-400 bg-red-50',
                Lockout: 'border border-red-400/50 text-red-400 bg-red-50',
                open: 'border border-green-500/50 text-green-500 bg-green-50',
                featured: 'border border-green-500/50 text-green-500 bg-green-50',
                'Insurance Accepted': 'border border-green-500/50 text-green-500 bg-green-50',
                'Contract Signed': 'border border-green-500/50 text-green-500 bg-green-50',
                Contingency: 'border border-green-500/50 text-green-500 bg-green-50',
                pending: 'border border-yellow-400/50 text-yellow-500 bg-yellow-50',
                'Soft Set': 'border border-yellow-400/50 text-yellow-500 bg-yellow-50',
                hidden: 'border border-orange-400/50 text-orange-400 bg-orange-50',
                Retail: 'border border-orange-400/50 text-orange-400 bg-orange-50',
                Skip: 'border border-black/20 text-black bg-black/10',
                'No Answer': 'border border-black/20 text-black bg-black/10',
                'Not Interested': 'border border-black/20 text-black bg-black/10'
            }
            return this.filteredOpportunities?.filter(m => m.address?.coordinates?.lat && m.address?.coordinates?.lng)?.map(m => {
                return {
                    id: m.id,
                    collection: 'opportunities',
                    status: m.status,
                    created: m.created,
                    coordinates: [m.address?.coordinates?.lng, m.address?.coordinates?.lat],
                    popup: `
                    <div class="hidden xs:block w-full max-w-xs text-left bg-white dark:bg-gray-800 divide-y dark:divide-gray-500 rounded-lg">

                        <div class="w-full p-4 flex items-center">
                            <div
                                class="w-12 h-12 shrink-0 mr-4 bg-center bg-cover rounded-full border-white dark:border-gray-400 bg-gray-400 dark:bg-gray-700 uppercase text-white dark:text-gray-400 flex items-center justify-center"
                                style="background-image: url(${m.selfie?.url})"
                            >
                                ${m.selfie?.url ? `` : `<div><i class="fad fa-house"></i></div>`}
                            </div>
                            <div class="flex flex-col items-start">
                                <div class="text-2xs px-1 py-[2px] mb-1 ${colorStatusMap[m.status]} rounded whitespace-nowrap font-semibold">${m.status || 'Skip' }${ m.revenue ? ` - $${m.revenue.toLocaleString()}` : ''}</div>
                                <div class="text-sm">${m.salesReps || m.ownerInfo?.firstName ? `${m.ownerInfo.firstName} ${m.ownerInfo?.lastName}` : 'No Name Recorded'}</div>
                                <div class="font-mono text-gray-400 text-xs"><span class="text-gray-800">C:</span> ${dayjs(m.created).format('M/D h:mmA')} | <span class="text-gray-800">U:</span> ${dayjs(m.updated).format('ddd h:mmA')}</div>
                                <!-- <div class="italic text-gray-400 text-xs">${m.address?.city}, ${m.address?.state}</div> -->
                            </div>
                        </div>

                    </div>
                    `
                }
            })
        }
    }
}
</script>

<style>

</style>
